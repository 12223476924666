import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { QuizModel } from "@/models";
import { Common } from "../Common";
import { ErrorCode, ErrorMessage } from "@/constant";

export class QuizForm {
    name = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) beginTime = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) endTime = '';
    city = '';
    school = '';
    class = '';
    teacher = "";
    grade = '';
    level = "";
    unit = "";
    num = 0;
    active = "";
    qid = '';
    token = window.localStorage.getItem('adminToken') as string;
}

type response = { [key: string]: unknown };

@Component<AdminQuizPaperEditController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminQuizPaperEditController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private quizForm = new QuizForm();
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;    
    private errNo!: number;
    private errorMsgMap: { [key: string]: string } = {
        beginTime: '',
        endTime: '',
    };

    public async created() {
        const item = {
            qid: this.$route.query.qid,
            token: this.token,
        }
        const editData = await QuizModel.getPaper(item) as response;
        this.assignDataToForm(editData);
    }

    private assignDataToForm(data: response) {
        Common.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            qid: string;
            name: string;
            beginTime: string;
            endTime: string;
            city: string;
            school: string;
            class: string;
            teacher: string;
            grade: string;
            unit: string;
            level: string;
            num: number;
            active: string;
        };
        this.quizForm.qid = item.qid;
        this.quizForm.name = item.name;
        this.quizForm.beginTime = item.beginTime;
        this.quizForm.endTime = item.endTime;
        this.quizForm.city = item.city;
        this.quizForm.school = item.school;
        this.quizForm.class = item.class;
        this.quizForm.teacher = item.teacher;
        this.quizForm.grade = item.grade;
        this.quizForm.unit = item.unit;
        this.quizForm.level = item.level;
        this.quizForm.num = item.num;
        this.quizForm.active = item.active;
    }

    private validateAndSubmit() {
        validate(this.quizForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        beginTime: '',
                        endTime: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    this.errNo = await QuizModel.editPaper(this.quizForm);
                    Common.checkToken(this.errNo);
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/quiz/paper");
        }
    }
}
